import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerParametros() {
    var url = ConfigAPI.baseURL + "parameters";

    return instance.get(url);
  },
  login(data) {
    var url = ConfigAPI.baseURL + "login";

    var params = {
      email: data.email,
      password: data.password
    }

    return instance.post(url, params);
  },
  recoveryPassword(data) {
    var params = data
    var url = ConfigAPI.baseURL + "recovery";
    return instance.post(url, params);
  },
  register(data) {
    var params = data
    var url = ConfigAPI.baseURL + "register-customer";
    return instance.post(url, params);
  },
  obtenerWidget() {
    var url = ConfigAPI.baseURL + "widget" + Session.getToken();

    return instance.get(url);
  },
  obtenerEmpleado() {
    var url = ConfigAPI.baseURL + "employee/employee" + Session.getToken();
    return instance.get(url);
  },
}

export default servicesAPI;
